jQuery(document).ready(function($) {
  $('body').css("opacity","1");
  $.fn.extend({
    animateCss: function(animationName, callback) {
      var animationEnd = (function(el) {
        var animations = {
          animation: 'animationend',
          OAnimation: 'oAnimationEnd',
          MozAnimation: 'mozAnimationEnd',
          WebkitAnimation: 'webkitAnimationEnd',
        };

        for (var t in animations) {
          if (el.style[t] !== undefined) {
            return animations[t];
          }
        }
      })(document.createElement('div'));

      this.addClass('animated ' + animationName).one(animationEnd, function() {
        $(this).removeClass('animated ' + animationName);

        if (typeof callback === 'function') callback();
      });

      return this;
    },
  });

  var swipercontainer = new Swiper('.hero-swiper', {
    loop: $('.hero-swiper .swiper-slide').length > 3 ? true : false,
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 20,
    speed: 1000,
    autoplay: {
     delay: 5000
     },
     navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },  
    breakpoints: {
      767: {
        slidesPerView: 2,
        slidesPerGroup: 2,        
        loop: $('.hero-swiper .swiper-slide').length > 2 ? true : false,
      },        
      425: {
        slidesPerView: 1,
        slidesPerGroup: 1,        
        loop: $('.hero-swiper .swiper-slide').length > 1 ? true : false,
      }
    },
    on: {
      init: function () {        
        setTimeout(function(){ 
          $('.hero-swiper').css("opacity","1");
        }, 200);
          //console.log("asd");
        },
    },
  });

var swiperclient = new Swiper('.swiper-client', {
    slidesPerView: 9,
    loop: $('.swiper-client .swiper-slide').length > 9 ? true : false,
    speed: 1000,
    autoplay: {
     delay: 5000
   },
   breakpoints: {
    991: {
      slidesPerView: 7,      
      loop: $('.swiper-client .swiper-slide').length > 7 ? true : false,
    },   
    767: {
      slidesPerView: 5,
      loop: $('.swiper-client .swiper-slide').length > 5 ? true : false,
    },        
    576: {
      slidesPerView: 3,      
      loop: $('.swiper-client .swiper-slide').length > 3 ? true : false,
    }
  }
});



var featureTl =  new TimelineMax();
featureTl.staggerFromTo(".product-grid, .col-client-grid", 0.5, {y: -30, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 0.2);

  var slideout = new Slideout({
    'panel': document.getElementById('panel'),
    'menu': document.getElementById('menumob'),
    'padding': 256,
    'tolerance': 70
  });


  $(".scrollbar").mCustomScrollbar({
    theme: "mittaltheme"
  });
  
  var fixed = document.querySelector('.headermob');

  slideout.on('translate', function(translated) {
    fixed.style.transform = 'translateX(' + translated + 'px)';
  });

  slideout.on('beforeopen', function() {
    fixed.style.transition = 'transform 300ms ease';
    fixed.style.transform = 'translateX(256px)';
    $('.hamburger').addClass('h-active');
  });

  slideout.on('beforeclose', function() {
    fixed.style.transition = 'transform 300ms ease';
    fixed.style.transform = 'translateX(0px)';
    $('.hamburger').removeClass('h-active');
  });

  slideout.on('open', function() {
    fixed.style.transition = '';
  });

  slideout.on('close', function() {
    fixed.style.transition = '';
  });

  var cls = $(".search").clone(true);
  $('.search-mob').prepend(cls);

  var cl = $(".main-nav .nav").children().not(".logo").clone(true)
  $('#menumob').prepend(cl).wrapInner('<ul></ul>');

  if (window.screen.width<=1199) {
    $(".search-box").clone().prependTo(".search-box-mob");
    $(".contactdetails").clone().prependTo(".contactdetails-mob");
    // $(".search-box").remove();
  }

  // mobile menu
  $('.hamburger').click(function(event) {
    $(this).toggleClass('h-active');
    slideout.toggle();
  });

  $(".search-btn").on('click', function(event) {
    $(".search-box").toggleClass("searchshow");
    if($(this).hasClass('flaticon-search'))
    {
      $(this).removeClass('flaticon-search').addClass("flaticon-close");
    }
    else{
      $(this).removeClass('flaticon-close').addClass("flaticon-search");
    }
  });

  $("[data-scroll]").each(function(index, el) {
    var $elm = $(this);
    var $offset =  $elm.data("anim-offset");
    $elmWatcher = scrollMonitor.create($elm, $offset);

    $elmWatcher.enterViewport(function() {
      $elm.addClass('animActive');
      $elm.find("[data-anim]").each(function() {
        $(this).css("animation-delay",+$(this).data("anim-delay")+"s" ).addClass("animated "+ $(this).data("anim")).css("visibility","visible");
      });
    });
  });

  var topoffset = 0;
  topoffset = $('.section-hero, .breadcrumb-section').height();

  if(topoffset < 150)
  {
    topoffset = 150;
  }
  var header = new Headroom(document.querySelector(".top-header"), {
    tolerance: 5,
    offset : topoffset,
    classes: {
      initial: "animated",
      pinned: "headroom--pinned",
      unpinned: "headroom--unpinned"
    }
  });
  header.init();

  $('[data-fancybox="gallery"]').fancybox({
    touch: true,
    buttons: [
    "close"
    ],
  });

  $(document).keyup(function(e) {
    if (e.keyCode == 27) 
    { 
      if($('.search-box').hasClass('searchshow'))
      {
        $(".search-box").toggleClass("searchshow");
        $(".search .search-btn").removeClass('flaticon-close').addClass("flaticon-search");
      }
    }
  });

  $(window).click(function() {
        if($('.search-box').hasClass('searchshow'))
          {
            $(".search-box").toggleClass("searchshow");
            $(".search .search-btn").removeClass('flaticon-close').addClass("flaticon-search");
          }
    });
    $('.search-box, .search').click(function(event){
        event.stopPropagation();
    });

  $(".fb,.tw,.lin").hover(function() {
    $(this).animateCss('bounceIn');
  }, function() {
  }); 

});